const ContactDetails = [
  {
    id: 1,
    name: "Srikanth Garlla",
    phone: "+1 (603) 217-4004",
    city: "Atlanta",
    state: "Georgia",
    image: require("./ContactPhotos/user.png"),
  },
  {
    id: 2,
    name: "Sheetal Pawar",
    phone: "+1 (636) 489-8585",
    city: "Atlanta",
    state: "Georgia",
    image: require("./ContactPhotos/user.png"),
  },
  {
    id: 3,
    name: "Arun Kumar Alladurgam",
    phone: "+1 (251) 509-5151",
    city: "Atlanta",
    state: "Georgia",
    image: require("./ContactPhotos/user.png"),
  },
  {
    id: 4,
    name: "Praveen Pippiri",
    phone: "+1 (404) 947-0924",
    city: "Atlanta",
    state: "Georgia",
    image: require("./ContactPhotos/user.png"),
  },
  {
    id: 5,
    name: "Sanjay Goswami",
    phone: "+1 (612) 801-1395",
    city: "New Jersey",
    state: "New Jersey",
    image: require("./ContactPhotos/user.png"),
  },
  {
    id: 6,
    name: "Harika Kurnala",
    phone: "+1 (732) 762-7795",
    city: "New Jersey",
    state: "New Jersey",
    image: require("./ContactPhotos/user.png"),
  },
  {
    id: 7,
    name: "Mahanthi",
    phone: "+1 (901) 609-9572",
    city: "New Jersey",
    state: "New Jersey",
    image: require("./ContactPhotos/user.png"),
  },
  {
    id: 8,
    name: "Divya Nadimpale",
    phone: "+1 (901) 212-5834",
    city: "Dallas",
    state: "Texas",
    image: require("./ContactPhotos/user.png"),
  },
  {
    id: 9,
    name: "Nanda Maharshi",
    phone: "+1 (510) 365-0866",
    city: "Dallas",
    state: "Texas",
    image: require("./ContactPhotos/user.png"),
  }, //Naresh
  {
    id: 10,
    name: "Naresh",
    phone: "+1 (480) 793-8380",
    city: "Dallas",
    state: "Texas",
    image: require("./ContactPhotos/user.png"),
  },
  {
    id: 11,
    name: "Devi Sreekanth Aruru",
    phone: "+1 (907) 302-8166",
    city: "Florida",
    state: "Florida",
    image: require("./ContactPhotos/user.png"),
  },
];

export default ContactDetails;
